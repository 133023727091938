import { useState } from "react";
import { Formik, Field } from "formik";
import { Button, Textarea, Select } from "components";
import { ScrollToError } from "utils";
import {
  TypeOfInstalationExternalDoorReplacement,
  SingleSectionElement,
  TypeOfRadiobox,
} from "features/addingMeasurement";
import { ExtraWork } from "features/addingMeasurement/ExternalDoorReplacement/components";

interface ExternalDoorReplacementGeneralInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const ExternalDoorReplacementGeneralInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: ExternalDoorReplacementGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);

  const handleOnSubmit = (values: any) => {
    console.log(values);
  };

  const typesOfSpoutLevelOptions = [
    {
      value: "real",
      label: "Rzeczywisty",
    },
    {
      value: "specifiedByInvestor",
      label: "określony przez inwestora",
    },
    {
      value: "specifiedByContractor",
      label: "Określony przez wykonawcę",
    },
  ];

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{ typeOfInstallation: "" }}
        // validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values, actions) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          // console.log(values, "wartości!!");
          if (submitCount > 0) {
            setIsValidated(true);
          }

          return (
            <form
              className="amfs__form form amfs__form--blinds"
              onSubmit={handleSubmit}
            >
              <ScrollToError />

              <Field
                values={values}
                errors={errors}
                label="Nazwa materiału"
                name="typeOfInstallation"
                component={TypeOfInstalationExternalDoorReplacement}
              />
              <SingleSectionElement
                touched={touched}
                name="spoutLevel"
                errors={errors}
              >
                <Field
                  label="Poziom wylewki"
                  name="spoutLevel"
                  type="number"
                  options={typesOfSpoutLevelOptions}
                  component={Select}
                  white
                />
              </SingleSectionElement>

              <Field
                errors={errors}
                name="typeOfDrive"
                label="Typ starych drzwi"
                half
                options={[
                  {
                    label: "Metalowe",
                    value: "radio",
                    uuid: "radio",
                  },
                  {
                    label: "PCV",
                    value: "radioPlusKey",
                    uuid: "radioPlusKey",
                  },
                  {
                    label: "Drewniane",
                    value: "manual",
                    uuid: "manual",
                  },
                  {
                    label: "Aluminiowe",
                    value: "key",
                    uuid: "key",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <Field isChecked={"sas"} values={values} component={ExtraWork} />
              <div className="am-separator" />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExternalDoorReplacementGeneralInformation;
