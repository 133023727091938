/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./one-day-modal.scss";
import "moment/locale/pl";
import moment from "moment";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import { PortalWithState } from "react-portal";
import classNames from "classnames";

interface OneDayModalProps {
  position: any;
  setPosition: any;
  card: any;
  day: Date;
  setCardPreview: any;
  activeEvent: any;
}

const OneDayModal = ({
  position,
  card,
  day,
  setPosition,
  setCardPreview,
  activeEvent,
}: OneDayModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [adjustedPosition, setAdjustedPosition] = useState(position);

  useEffect(() => {
    if (position && position.x) {
      const modalHeight = modalRef.current?.offsetHeight || 0;
      const modalWidth = modalRef.current?.offsetWidth || 0;
      setAdjustedPosition(validatePosition(position, modalHeight, modalWidth));
    }
  }, [position]);

  const validatePosition = (
    pos: { top: number; left: number },
    modalHeight: number,
    modalWidth: number
  ) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let newTop = pos.top;
    let newLeft = pos.left;

    if (pos.top + modalHeight > viewportHeight) {
      newTop = viewportHeight - modalHeight - 20;
    }

    if (pos.left + modalWidth > viewportWidth) {
      newLeft = viewportWidth - modalWidth - 10;
    }

    if (newTop < 0) {
      newTop = 10;
    }

    if (newLeft < 0) {
      newLeft = 10;
    }

    return { top: newTop, left: newLeft };
  };

  const handleOnClose = () => {
    setCardPreview({ isOpen: false, date: null, eventsOnDate: null });
    setPosition(null);
  };
  const dayOfWeek = moment(day).day();

  const popupStyle: React.CSSProperties = {
    position: "fixed",
    top: adjustedPosition?.top || 0,
    left:
      dayOfWeek === 0
        ? (adjustedPosition?.left ?? 0) - 250
        : dayOfWeek === 6
        ? (adjustedPosition?.left ?? 0) - 150
        : adjustedPosition?.left ?? 0,
  };

  const formattedDay = moment(day).format("dddd, D MMMM");
  const capitalizedDay =
    formattedDay.charAt(0).toUpperCase() + formattedDay.slice(1);
  const isOpenModal =
    !activeEvent &&
    !!(position && position.x) &&
    moment(card.date).format("M") === moment(day).format("M");

  return (
    <>
      {isOpenModal && adjustedPosition && adjustedPosition.top && (
        <>
          <PortalWithState
            closeOnEsc={true}
            closeOnOutsideClick={true}
            defaultOpen={card.isOpen}
            onClose={() => handleOnClose()}
          >
            {({ portal }) => (
              <>
                {portal(
                  <>
                    {isOpenModal && (
                      <div
                        ref={modalRef}
                        className="one-day-modal"
                        style={popupStyle}
                      >
                        <div className="one-day-modal__content">
                          <div
                            className="one-day-modal__close"
                            onClick={() => handleOnClose()}
                          >
                            <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                          </div>
                          <div className="one-day-modal__day">
                            {capitalizedDay}
                          </div>
                          <div className="one-day-modal__list">
                            {card.eventsOnDate.map((event: any) => {
                              const { original } = event;
                              const isAdditional = original.initialDate;
                              const time =
                                original.start || original.archivalStart;
                              const formattedTime = time
                                ? time.replace(/^0/, "")
                                : "";
                              const name = original.title;
                              const color =
                                original.employees &&
                                original.employees.length > 0 &&
                                original.employees[0].userColor;
                              const oneDayModalClass = classNames(
                                "one-day-modal__item",
                                {
                                  "one-day-modal__item--initial": isAdditional,
                                }
                              );
                              return (
                                <div
                                  key={event.index}
                                  className={oneDayModalClass}
                                >
                                  {!isAdditional && (
                                    <>
                                      <div
                                        className="one-day-modal__item-color"
                                        style={{ backgroundColor: color }}
                                      />
                                      <div className="one-day-modal__item-hour">
                                        {formattedTime}
                                      </div>
                                    </>
                                  )}
                                  <div className="one-day-modal__item-name name">
                                    {name}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </PortalWithState>
        </>
      )}
    </>
  );
};

export default OneDayModal;
