import { OrderStatus, Button, SVG } from "components";
import { ROUTE_ENUM, USER_ROLE, ORDER_ENUM, SVG_TYPE } from "enums";
import { hasOrderStatus } from "utils";

const isInstallationToBeReleased = (status: string) =>
  status === "installationToBeReleased" && true;

const handleButtonClick = (
  e: any,
  record: any,
  navigate: any,
  setSearchParams: any,
  page: number | string,
  userRole: string,
  activeFloatingMenuItem: any
) => {
  const isMeasurer = userRole === USER_ROLE.measurer;
  const isFitter = userRole === USER_ROLE.fitter;
  const isFitterOrMeasurer = isMeasurer || isFitter;
  e.stopPropagation();
  setSearchParams({
    page,
    uuid: record.uuid,
    tab: activeFloatingMenuItem?.value,
  });

  const isFixedInstallation =
    activeFloatingMenuItem?.value === ORDER_ENUM.FIXED_INSTALLATION;

  if (isFixedInstallation) {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${record.uuid}&tab=${
        ORDER_ENUM.INSTALLATION_TO_BE_RELEASED
      }${`&edit=true`}`
    );
  } else {
    navigate(
      `${
        isFitterOrMeasurer
          ? ROUTE_ENUM.ACTIVE_ORDER
          : ROUTE_ENUM.ARRANGING_MEASUREMENT
      }?${
        isFitterOrMeasurer
          ? `orderUuid=${record.uuid}&page=${page}${
              record.groupUuid ? `&groupUuid=${record.groupUuid}` : ""
            }`
          : `uuid=${record.uuid}&tab=${activeFloatingMenuItem?.value}${
              record.status === ORDER_ENUM.FIXED_INSTALLATION
                ? `&edit=true`
                : ``
            }`
      }`
    );
  }
};

export const desktopColumns = (
  navigate: any,
  setSearchParams: any,
  page: any,
  userRole: string,
  activeFloatingMenuItem: any,
  setModalManage: any
) => {
  const isFixedInstallation =
    activeFloatingMenuItem?.value === ORDER_ENUM.FIXED_INSTALLATION;
  const isAdmin = USER_ROLE.admin === userRole;
  const isArchived = activeFloatingMenuItem?.value === ORDER_ENUM.ARCHIVED;

  if (isFixedInstallation) {
    return [
      {
        title: "Nazwa zlecenia",
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (original: any) => (
          <div className="order-status__name">
            <p>{original}</p>
          </div>
        ),
      },
      {
        title: "Imię i nazwisko klienta",
        dataIndex: "clientName",
        key: "clientName",
        width: 220,
      },
      {
        title: "Przypisany pracownik",
        width: 220,
      },
      {
        title: "Termin pomiaru",
        width: 220,
      },
      {
        title: "Status",
        width: 212,
        render: (original: any) => {
          return (
            <div
              className="order-status__table"
              onClick={(e) => e.stopPropagation()}
            >
              <OrderStatus status={original.status} />
            </div>
          );
        },
      },
      {
        title: "Akcje",
        width: 144,
        render: (record: any) => {
          return (
            <Button
              onClick={(e: any) =>
                handleButtonClick(
                  e,
                  record,
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem
                )
              }
              className="order-status__action-btn"
              label="Edycja"
              size="small"
            />
          );
        },
      },
    ];
  } else if (isArchived) {
    return [
      {
        title: "Nazwa zlecenia",
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (original: any) => (
          <div className="order-status__name">
            <p>{original}</p>
          </div>
        ),
      },
      {
        title: "Imię i nazwisko klienta",
        dataIndex: "clientName",
        key: "clientName",
        width: 220,
      },
      {
        title: "Telefon",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: 152,
      },
      {
        title: "Adres",
        dataIndex: "address",
        key: "address",
        width: 276,
      },
      {
        title: "Status",
        width: 242,
        render: (original: any) => (
          <div className="order-status__table">
            <OrderStatus
              status={original.status}
              isSubMenu
              orderDetails={original}
            />
            {isInstallationToBeReleased(original.status) && !isAdmin && (
              <div className="order-status__counters">
                {original.arrangedInstallation}/{original.allInstallation}
              </div>
            )}
          </div>
        ),
      },
    ];
  } else {
    const columns = [
      {
        title: "Nazwa zlecenia",
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (original: any) => (
          <div className="order-status__name">
            <p>{original}</p>
          </div>
        ),
      },
      {
        title: "Imię i nazwisko klienta",
        dataIndex: "clientName",
        key: "clientName",
        width: 220,
      },
      {
        title: "Telefon",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: 152,
      },
      {
        title: "Adres",
        dataIndex: "address",
        key: "address",
        width: 276,
      },
      {
        title: "Status",
        width: 242,
        render: (original: any) => (
          <div className="order-status__table">
            <OrderStatus
              status={original.status}
              isSubMenu
              orderDetails={original}
            />
            {isInstallationToBeReleased(original.status) && !isAdmin && (
              <div className="order-status__counters">
                {original.arrangedInstallation}/{original.allInstallation}
              </div>
            )}
          </div>
        ),
      },
    ];

    if (!isAdmin) {
      columns.push({
        title: "Akcje",
        width: 144,
        render: (record: any) => {
          const { status } = record;
          const {
            FIXED_MEASUREMENTS,
            FIXED_INSTALLATION,
            FIXED_VERIFICATION,
            MEASUREMENTS_TO_BE_RELEASED,
            MEASUREMENTS_DONE,
          } = ORDER_ENUM;
          const isArchiveActive = hasOrderStatus(status, [
            MEASUREMENTS_TO_BE_RELEASED,
            FIXED_MEASUREMENTS,
            MEASUREMENTS_DONE,
          ]);
          const isStatusFixedMeasurements = status === FIXED_MEASUREMENTS;
          const isStatusFixedInstallation = status === FIXED_INSTALLATION;
          const isStatusFixedVerification = status === FIXED_VERIFICATION;
          const isMeasurementsDone = status === MEASUREMENTS_DONE;
          const isEdit =
            isStatusFixedMeasurements ||
            isStatusFixedInstallation ||
            isStatusFixedVerification;
          const isMeasurer = userRole === USER_ROLE.measurer;
          const isScheduleManager = userRole === USER_ROLE.scheduleManager;
          const isFitter = userRole === USER_ROLE.fitter;
          const isFitterOrMeasurer = isMeasurer || isFitter;
          const isFixedOrers = activeFloatingMenuItem.value === "fixedOrders";
          const isAdditionalVisit = record.type === "additionalVisit";
          return (
            <div className="order-status__actions order-status__actions--right">
              {!isFixedOrers && !isMeasurementsDone && (
                <>
                  {isAdditionalVisit && (
                    <div className="order-status__action-warning">
                      <SVG type={SVG_TYPE.EXCLAMATION} />
                    </div>
                  )}
                  <Button
                    onClick={(e: any) =>
                      handleButtonClick(
                        e,
                        record,
                        navigate,
                        setSearchParams,
                        page,
                        userRole,
                        activeFloatingMenuItem
                      )
                    }
                    className="order-status__action-btn"
                    label={
                      isFitterOrMeasurer
                        ? "Wykonaj"
                        : isEdit
                        ? "Edytuj"
                        : "Umów"
                    }
                    size="small"
                  />
                </>
              )}
              {isScheduleManager && (
                <div className="order-status__actions--edit">
                  {record.additionalVisitRequired ? (
                    <div className="order-status__action-warning">
                      <SVG type={SVG_TYPE.EXCLAMATION} />
                    </div>
                  ) : null}

                  <div
                    onClick={() =>
                      setTimeout(() => {
                        setModalManage({
                          type: "addOrder",
                          order: { ...record, isEdit: true },
                        });
                      }, 50)
                    }
                  >
                    <SVG type={SVG_TYPE.EDIT_SECONDARY} />
                  </div>
                  {isArchiveActive && (
                    <div
                      className="order-status__action-archive"
                      onClick={() => {
                        setTimeout(() => {
                          setModalManage({
                            type: "archive",
                            order: { ...record },
                          });
                        }, 1);
                      }}
                    >
                      <SVG type={SVG_TYPE.ARCHIVING} />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
      });
    }

    return columns;
  }
};

export const mobileColumns = (
  navigate: any,
  setSearchParams: any,
  page: any,
  userRole: string,
  activeFloatingMenuItem: any,
  setModalManage: any
) => [
  {
    dataIndex: "status",
    key: "status",
    width: 20,
    render: (original: any) => <OrderStatus status={original} />,
  },
  {
    dataIndex: "name",
    key: "name",
    width: 350,
  },
  {
    width: 144,
    render: (record: any) => {
      const { status } = record;
      const {
        FIXED_MEASUREMENTS,
        FIXED_INSTALLATION,
        FIXED_VERIFICATION,
        MEASUREMENTS_TO_BE_RELEASED,
        MEASUREMENTS_DONE,
      } = ORDER_ENUM;
      const { measurer, admin, fitter, scheduleManager } = USER_ROLE;
      const isArchiveActive = hasOrderStatus(status, [
        MEASUREMENTS_TO_BE_RELEASED,
        FIXED_MEASUREMENTS,
        MEASUREMENTS_DONE,
      ]);
      const isStatusFixedMeasurements = record.status === FIXED_MEASUREMENTS;
      const isStatusFixedInstallation = record.status === FIXED_INSTALLATION;
      const isStatusFixedVerification = record.status === FIXED_VERIFICATION;
      const isMeasurementsDone = record.status === MEASUREMENTS_DONE;
      const isScheduleManager = userRole === scheduleManager;
      const isEdit =
        isStatusFixedMeasurements ||
        isStatusFixedInstallation ||
        isStatusFixedVerification;
      const isMeasurer = userRole === measurer;
      const isAdmin = userRole === admin;
      const isFitter = userRole === fitter;
      const isFitterOrMeasurer = isMeasurer || isFitter;
      return (
        <div className="order-status__actions">
          {!isAdmin && !isMeasurementsDone && (
            <Button
              onClick={(e: any) =>
                handleButtonClick(
                  e,
                  record,
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem
                )
              }
              className="order-status__action-btn"
              label={
                isFitterOrMeasurer ? "Wykonaj" : isEdit ? "Edytuj" : "Umów"
              }
              size="small"
            />
          )}
          {isScheduleManager && (
            <div className="order-status__actions--edit">
              {record.additionalVisitRequired ? (
                <div className="order-status__action-warning">
                  <SVG type={SVG_TYPE.EXCLAMATION} />
                </div>
              ) : null}
              <div
                onClick={() =>
                  setModalManage({
                    type: "addOrder",
                    order: { ...record, isEdit: true },
                  })
                }
              >
                <SVG type={SVG_TYPE.EDIT_SECONDARY} />
              </div>
              {isArchiveActive && (
                <div
                  className="order-status__action-archive"
                  onClick={() => {
                    setTimeout(() => {
                      setModalManage({
                        type: "archive",
                        order: { ...record },
                      });
                    }, 1);
                  }}
                >
                  <SVG type={SVG_TYPE.ARCHIVING} />
                </div>
              )}
            </div>
          )}
        </div>
      );
    },
  },
];
