/* eslint-disable react-hooks/exhaustive-deps */
import {
  SVG_TYPE,
  ROUTE_ENUM,
  USER_ROLE,
  ARCHIVAL_TYPE,
  ORDER_ENUM,
} from "enums";
import { SVG, Button } from "components";
import { Tooltip } from "react-tooltip";
import "./calendar-event-preview.scss";
import moment from "moment";
import "moment/locale/pl";
import { useNavigate } from "react-router";
import { UserType } from "types/UsersTypes";
import { OrderPreview } from "features/orders";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { hasOrderStatus } from "utils";

interface CalendarEventPreviewType {
  event: any;
  user: UserType;
  setActiveEvent?: any;
  activeEvent?: any;
}
const CalendarEventPreview = ({
  event,
  user,
  setActiveEvent,
  activeEvent,
}: CalendarEventPreviewType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { measurer, admin, advisor } = USER_ROLE;
  const { ARRANGING_MEASUREMENT, ACTIVE_ORDER, ORDERS_LIST } = ROUTE_ENUM;
  const { FIXED_MEASUREMENTS, FIXED_VERIFICATION } = ORDER_ENUM;
  const uuid = searchParams.get("uuid");
  const isMeasurer = user?.role === measurer;
  const isAdminOrAdvisor = user?.role === admin || user?.role === advisor;
  const isOpen = activeEvent === event.original && isAdminOrAdvisor;

  useEffect(() => {
    if (uuid && !activeEvent) {
      if (uuid === event.original.uuid) {
        setActiveEvent(event.original);
      }
    }
  }, []);

  const handleClickEdit = () => {
    navigate(
      `${ARRANGING_MEASUREMENT}?uuid=${event.original.uuid}&tab=${
        event.original.status
      }&edit=true${
        event.original.orderInstallationUuid
          ? `&groupUuid=${event.original.orderInstallationUuid}`
          : ""
      }`
    );
  };

  const handleClose = () => {
    const dashboard = document.getElementsByClassName("dashboard");
    if (dashboard.length > 0) {
      const dashboardElement = dashboard[0] as HTMLElement;
      dashboardElement.click();
    }
  };

  const handleAfterHide = () => {
    const elements = document.querySelectorAll(".cpp-day__tooltip-open");
    elements.forEach((element) => {
      element.classList.remove("cpp-day__tooltip-open");
    });
  };

  moment.locale("pl");
  const formattedDate = moment(event.original.date, "DD/MM/YYYY").format(
    "dddd, D MMMM"
  );
  const isMonday = moment(event.original.date, "DD/MM/YYYY").day() === 1;
  const isEndDate = event.original.dateEnd;

  const tooltipPlace = isMonday && !isEndDate ? "bottom-start" : "bottom";

  const handleClickOrder = () => {
    navigate(`${ACTIVE_ORDER}?orderUuid=${event.original.uuid}`);
  };

  const handleOnClose = () => {
    setActiveEvent(null);
    const params = new URLSearchParams(window.location.search);
    if (params.has("uuid")) {
      params.delete("uuid");
      navigate({ search: params.toString() }, { replace: true }); // Replace current history entry
    }
  };

  const isEditActive =
    !isMeasurer &&
    (event.original.type === ARCHIVAL_TYPE.INSTALLATION_PLANNED ||
      hasOrderStatus(event.original.status, [
        FIXED_VERIFICATION,
        FIXED_MEASUREMENTS,
      ]));

  const listOrder = () => {
    const isActive = event.original.page && event.original.listStatus;
    if (isActive) {
      const handleClick = () =>
        navigate(
          `${ORDERS_LIST}?page=${event.original.page}&tab=${event.original.listStatus}&orderUuid=${event.original.uuid}`
        );
      return (
        <div className="cpp__details" onClick={() => handleClick()}>
          Szczegóły zlecenia <SVG type={SVG_TYPE.LONG_ARROW} />
        </div>
      );
    }
  };

  return (
    <div>
      {isAdminOrAdvisor && event ? (
        <OrderPreview
          authorizedUser={user}
          isOpen={isOpen}
          order={event.original}
          onClose={() => handleOnClose()}
          CalendarEventPreview={true}
        />
      ) : (
        <Tooltip
          afterHide={handleAfterHide}
          className="cpp"
          anchorSelect={`#cpp-afera--${event.index}`}
          clickable
          openOnClick
          place={tooltipPlace}
        >
          <div className="cpp__wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="cpp__content" onClick={(e) => e.stopPropagation()}>
              <div className="cpp__top" onClick={(e) => e.stopPropagation()}>
                <div className="cpp__actions">
                  {isEditActive && (
                    <>
                      <div
                        className="cpp__action"
                        onClick={() => handleClickEdit()}
                      >
                        <SVG type={SVG_TYPE.EDIT_PENCIL} />
                      </div>
                    </>
                  )}
                  <div className="cpp__action" onClick={() => handleClose()}>
                    <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                  </div>
                </div>
              </div>
              <div className="cpp__event">
                <div className="cpp__event-name">{event.original.title}</div>
                <div className="cpp__event-date">
                  {event?.original?.initialDate ? (
                    moment(event.original.date, "DD/MM/YYYY", true).isValid() &&
                    moment(
                      event.original.dateEnd,
                      "DD/MM/YYYY",
                      true
                    ).isValid() ? (
                      moment(event.original.date, "DD/MM/YYYY").isSame(
                        moment(event.original.dateEnd, "DD/MM/YYYY"),
                        "month"
                      ) ? (
                        `${moment(event.original.date, "DD/MM/YYYY").format(
                          "D"
                        )}-${moment(
                          event.original.dateEnd,
                          "DD/MM/YYYY"
                        ).format("D")} ${moment(
                          event.original.date,
                          "DD/MM/YYYY"
                        ).format("MMMM")}`
                      ) : (
                        `${moment(event.original.date, "DD/MM/YYYY").format(
                          "DD/MM/YYYY"
                        )} - ${moment(
                          event.original.dateEnd,
                          "DD/MM/YYYY"
                        ).format("DD/MM/YYYY")}`
                      )
                    ) : (
                      "Nieprawidłowy format daty"
                    )
                  ) : (
                    <>
                      {formattedDate} {event.original.start}-
                      {event.original.end}
                    </>
                  )}
                </div>
                <div className="cpp__event-address">
                  {event.original.address}
                </div>
                {!isMeasurer && (
                  <div className="cpp__event-user">
                    {event.original.employees &&
                      event.original.employees.length > 0 && (
                        <>
                          <div
                            style={{
                              backgroundColor: event.original.userColor,
                            }}
                            className="cpp__event-user-box"
                          ></div>
                          <div className="cpp__event-user-name">
                            {event.original.employees.map(
                              (item: any, index: number) => {
                                const isMoreThanOneAndNotLast =
                                  index + 1 < event.original.employees.length;
                                return (
                                  <>
                                    {item.name
                                      ? item.name
                                      : `${item.firstName} ${item.lastName}`}
                                    {isMoreThanOneAndNotLast ? ", " : ""}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                  </div>
                )}
                {isMeasurer && (
                  <div className="cpp__buttons">
                    <Button
                      label="Wykonaj"
                      onClick={() => handleClickOrder()}
                      size="small"
                    />
                  </div>
                )}
                {listOrder()}
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default CalendarEventPreview;
