/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Switch, Textarea } from "components";
import {
  SingleSectionElement,
  HoleDimension,
  AdditionalWarning,
} from "features/addingMeasurement";
import { useStartMeasurement } from "hooks/measurements";
import { ScrollToError } from "utils";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import { usePutExternalDoorsInfo } from "hooks/measurements";
import "./external-door-in-row-measurement-values.scss";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { handleMeasurementSuccess } from "features/addingMeasurement/utils";

interface ExternalDoorInRowMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const ExternalDoorInRowMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: ExternalDoorInRowMeasurementValuesType) => {
  const [isErrorDimension, setIsErrorDimension] = useState(false);
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const [isAddNew, setIsAddNew] = useState(false);
  const initialValues = {
    measurementUuid: measurementUuid,
    roomName: initialMeasurementInfo?.roomName || "",
    durability: initialMeasurementInfo?.durability || "left",
    openingDirection: initialMeasurementInfo?.openingDirection || "outward",
    holeSizeWidth: initialMeasurementInfo?.holeSizeWidth || "",
    holeSizeHeight: initialMeasurementInfo?.holeSizeHeight || "",
    heightFromZeroLevel: initialMeasurementInfo?.heightFromZeroLevel || "",
    customDoors: initialMeasurementInfo?.customDoors || false,
    doorWidth: initialMeasurementInfo?.doorWidth || "",
    doorHeight: initialMeasurementInfo?.doorHeight || "",
    doorSideLighting: initialMeasurementInfo?.doorSideLighting || "",
    doorTopLighting: initialMeasurementInfo?.doorTopLighting || "",
    doorBottomExtension: initialMeasurementInfo?.doorBottomExtension || "",
    isTheOpeningCorrect: initialMeasurementInfo?.isTheOpeningCorrect || false,
    whatShouldBeTheCorrectWidth:
      initialMeasurementInfo?.whatShouldBeTheCorrectWidth || "",
    whatShouldBeTheCorrectHeight:
      initialMeasurementInfo?.whatShouldBeTheCorrectHeight || "",
    whoIsToPrepareTheOpening:
      initialMeasurementInfo?.whoIsToPrepareTheOpening || "investor",
    replacementDoor: initialMeasurementInfo?.replacementDoor || false,
    manufacturer: initialMeasurementInfo?.manufacturer || "",
    model: initialMeasurementInfo?.model || "",
    color: initialMeasurementInfo?.color || "",
    typeOfDoorFrame: initialMeasurementInfo?.typeOfDoorFrame || "",
    doorHandle: initialMeasurementInfo?.doorHandle || "",
    doorKnocker: initialMeasurementInfo?.doorKnocker || "",
    peephole: initialMeasurementInfo?.peephole || false,
    glassPanels: initialMeasurementInfo?.glassPanels || "",
    powerSupply: initialMeasurementInfo?.powerSupply || "",
    dripEdge: initialMeasurementInfo?.dripEdge || false,
    doorAbbrevation: initialMeasurementInfo?.doorAbbrevation || "",
    contactSensors: initialMeasurementInfo?.contactSensors || false,
    comments: initialMeasurementInfo?.comments || "",
    warningComment: initialMeasurementInfo?.warningComment || "",
  };

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");

  const { mutate } = usePutExternalDoorsInfo({
    onSuccess: (data) => {
      if (isAddNew) {
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
      } else {
        goToList();
      }
    },
  });

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      handleMeasurementSuccess(data, searchParams, setSearchParams, setStep);
    },
  });

  const handleOnSubmit = async (values: any) => {
    await mutate(values);
  };

  const validationSchema = Yup.object().shape({
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    holeSizeWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeSizeHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    heightFromZeroLevel: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    customDoors: Yup.boolean().required(VALIDATION_MESSAGES.REQUIRED),
    doorSize: Yup.string().test(
      "check-door-size",
      "Wszystkie pola są wymagane dla doorSize",
      function () {
        const { doorWidth, doorHeight, doorBottomExtension } = this.parent;
        const isAnyFieldEmpty = [
          doorWidth,
          doorHeight,
          doorBottomExtension,
        ].some(
          (field) => field === undefined || field === "" || field === null
        );
        return !isAnyFieldEmpty;
      }
    ),
    whatShouldBeTheCorrectWidth: Yup.number().test(
      "whatShouldBeTheCorrectWidth-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { isTheOpeningCorrect } = this.parent;
        return isTheOpeningCorrect ? true : value !== undefined;
      }
    ),
    whatShouldBeTheCorrectHeight: Yup.number().test(
      "whatShouldBeTheCorrectHeight-test",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { isTheOpeningCorrect } = this.parent;
        return isTheOpeningCorrect ? true : value !== undefined;
      }
    ),
    warningComment: isErrorDimension
      ? Yup.string().required(VALIDATION_MESSAGES.REQUIRED)
      : Yup.string(),
  });

  const handleCheckValidateDimensions = (
    values: any,
    setFieldError: any,
    errors: any
  ) => {
    const {
      doorWidth,
      doorSideLighting,
      holeSizeWidth,
      doorHeight,
      doorTopLighting,
      doorBottomExtension,
      holeSizeHeight,
      warningComment,
    } = values;

    if (
      (doorWidth && holeSizeWidth) ||
      (doorHeight && doorBottomExtension && holeSizeHeight)
    ) {
      const holeWidth = parseInt(holeSizeWidth, 10);
      const holeHeight = parseInt(holeSizeHeight, 10);
      const doorWidthParse = parseInt(doorWidth, 10);
      const doorSideLightingParse = isNaN(parseInt(doorSideLighting, 10))
        ? 0
        : parseInt(doorSideLighting, 10);
      const doorHeightParse = parseInt(doorHeight, 10);
      const doorTopLightingParse = isNaN(parseInt(doorTopLighting, 10))
        ? 0
        : parseInt(doorTopLighting, 10);
      const doorBottomExtensionParse = parseInt(doorBottomExtension, 10);

      const totalWidth = doorWidthParse + doorSideLightingParse;
      const totalHeight =
        doorHeightParse + doorTopLightingParse + doorBottomExtensionParse;

      let isError = false;

      const widthDifference = holeWidth - totalWidth;
      if (widthDifference < 20 || widthDifference > 50) {
        isError = true;
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
      }

      const heightDifference = holeHeight - totalHeight;
      if (heightDifference < 20 || heightDifference > 50) {
        isError = true;
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
      }

      if (!isError) {
        setIsErrorDimension(false);
      } else {
        setIsErrorDimension(true);
      }
    }

    return false;
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          handleCheckValidateDimensions(values, setFieldError, errors);
          return (
            <form
              className="amfs__form form amfs--with-warning-comment"
              onSubmit={handleSubmit}
            >
              <ScrollToError errorDimension={isErrorDimension} />
              <SingleSectionElement
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="durability"
                errors={errors}
              >
                <Field
                  first="Lewe"
                  second="Prawe"
                  stringMode
                  label="Stronność"
                  name="durability"
                  valueFirst="left"
                  valueSecond="right"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="openingDirection"
                errors={errors}
              >
                <Field
                  label="Kierunek otwierania"
                  first="Do wewnątrz"
                  second="Na zewnątrz"
                  valueFirst="inward"
                  valueSecond="outward"
                  stringMode
                  name="openingDirection"
                  component={Switch}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                aboveZero
                label="Wymiar otworu"
                component={HoleDimension}
              />
              <SingleSectionElement
                touched={touched}
                name="doorSize"
                errors={errors}
                className="padding-top-separator"
              >
                <div className="doors-form">
                  <div className="am-section-heading">
                    Wymiar docelowych drzwi
                  </div>

                  <Field
                    label="Szerokość"
                    name="doorWidth"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    label="Wysokość"
                    name="doorHeight"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    optional
                    label="Doświetlenie boczne"
                    name="doorSideLighting"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    optional
                    label="Doświetlenie górne"
                    name="doorTopLighting"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    label="Poszerzenie dolne"
                    name="doorBottomExtension"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />
                </div>
              </SingleSectionElement>

              <AdditionalWarning
                isErrorDimension={isErrorDimension}
                errors={errors}
                values={values}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
              />

              <SingleSectionElement
                touched={touched}
                name="isTheOpeningCorrect"
                errors={errors}
              >
                <Field
                  checked={values.isTheOpeningCorrect}
                  label={`Czy otwór jest prawidłowy?`}
                  name="isTheOpeningCorrect"
                  component={Switch}
                />
              </SingleSectionElement>

              {values.isTheOpeningCorrect === false && (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="whatShouldBeTheCorrectWidth"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Prawidłowa szerokość otworu"
                      name="whatShouldBeTheCorrectWidth"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                  <SingleSectionElement
                    touched={touched}
                    name="whatShouldBeTheCorrectHeight"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Prawidłowa wysokość otworu"
                      name="whatShouldBeTheCorrectHeight"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                  <SingleSectionElement
                    touched={touched}
                    name="whoIsToPrepareTheOpening"
                    errors={errors}
                  >
                    <Field
                      checked={values.whoIsToPrepareTheOpening === "investor"}
                      label={`Kto ma przygotować otwór?`}
                      name="whoIsToPrepareTheOpening"
                      first="Inwestor"
                      second="MartDom"
                      stringMode
                      valueFirst="investor"
                      valueSecond="martdom"
                      component={Switch}
                    />
                  </SingleSectionElement>
                </>
              )}

              <SingleSectionElement
                touched={touched}
                name="replacementDoor"
                errors={errors}
              >
                <Field
                  checked={values.replacementDoor}
                  label={`Drzwi zastępcze`}
                  name="replacementDoor"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="manufacturer"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Producent"
                  maxLength={30}
                  name="manufacturer"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="model"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Model"
                  maxLength={30}
                  name="model"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="color"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Kolor"
                  maxLength={30}
                  name="color"
                  component={Input}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name="typeOfDoorFrame"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  maxLength={30}
                  label="Rodzaj ościeżnicy"
                  name="typeOfDoorFrame"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="doorHandle"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Klamka"
                  maxLength={30}
                  name="doorHandle"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="doorKnocker"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Antaba"
                  maxLength={30}
                  name="doorKnocker"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="peephole"
                errors={errors}
              >
                <Field
                  checked={values.peephole}
                  label={`Wizjer`}
                  name="peephole"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="glassPanels"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Szklenie"
                  name="glassPanels"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="powerSupply"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  maxLength={30}
                  label="Zasilacz"
                  name="powerSupply"
                  component={Input}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name="dripEdge"
                errors={errors}
              >
                <Field
                  checked={values.dripEdge}
                  label={`Okapnik`}
                  name="dripEdge"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="doorAbbrevation"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Skrót drzwi"
                  name="doorAbbrevation"
                  rightPlaceholder="mm"
                  type="number"
                  maxLength={6}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="contactSensors"
                errors={errors}
              >
                <Field
                  checked={values.contactSensors}
                  label={`Kontraktrony`}
                  name="contactSensors"
                  component={Switch}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExternalDoorInRowMeasurementValues;
