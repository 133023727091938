/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Textarea, Button, SVG, Modal } from "components";
import { SVG_TYPE } from "enums";
import "./follow-up-meeting.scss";
import { usePutAdditionalVisit } from "hooks/orders";
import { Formik, Field } from "formik";

interface FollowUpMeetingProps {
  groupUuid: string | null | undefined;
  groupInstallation: any;
  installation?: any;
}
const FollowUpMeeting = ({
  groupUuid,
  groupInstallation,
}: FollowUpMeetingProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const { additionalVisit, additionalVisitComments } = groupInstallation;
  const isAdditionalVisit =
    !!additionalVisit &&
    additionalVisitComments !== "" &&
    additionalVisitComments !== null &&
    !isEdit;
  const { mutate: putAdditionalVisit } = usePutAdditionalVisit({
    onSuccess: () => {
      setIsEdit(false);
      setIsCancelOpen(false);
    },
  });

  const handleUpdate = (values: any) => {
    const { comments } = values;
    groupUuid &&
      putAdditionalVisit({
        groupUuid: groupUuid,
        checkbox: comments !== "" ? true : false,
        comments: comments,
      });
  };

  const handleCancelMeeting = () => {
    groupUuid &&
      putAdditionalVisit({
        groupUuid: groupUuid,
        checkbox: false,
        comments: "",
      });
  };

  return (
    <>
      <Modal
        className="delete-activity follow-up-meeting__modal"
        isOpened={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
      >
        <>
          <div className="modal-heading">Wycofanie montażu uzupełniającego</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz wycofać konieczność umówienia montażu
            uzupełniającego dla twojej grupy monterskiej?
          </div>
          <div className="modal-buttons">
            <Button
              stroke
              onClick={() => setIsCancelOpen(false)}
              label="Anuluj"
            />
            <Button onClick={() => handleCancelMeeting()} label="Potwierdź" />
          </div>
        </>
      </Modal>
      <div className="active-order__box follow-up-meeting">
        <div className="receipt-protocol__client  ">
          <div className="active-order__heading receipt-protocol--customer-comments follow-up-meeting__comments">
            Montaż uzupełniajcy
            <span>(opcjonalne)</span>
          </div>
        </div>

        <Formik
          initialValues={{
            comments: groupInstallation?.additionalVisitComments || "",
          }}
          enableReinitialize
          onSubmit={async (values: any) => handleUpdate(values)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit }) => {
            return (
              <form className="follow-up-meeting__form">
                {isAdditionalVisit && (
                  <div className="follow-up-meeting__actions">
                    <div
                      className="follow-up-meeting__action"
                      onClick={() => setIsCancelOpen(true)}
                    >
                      <SVG type={SVG_TYPE.CANCEL} />
                      Wycofaj
                    </div>
                    <div
                      className="follow-up-meeting__action"
                      onClick={() => setIsEdit(true)}
                    >
                      <SVG type={SVG_TYPE.EDIT} />
                      Edytuj
                    </div>
                  </div>
                )}
                <Field
                  disabled={isAdditionalVisit}
                  label="Podaj powód"
                  name="comments"
                  maxLength={255}
                  placeholder=""
                  component={Textarea}
                />
                {!isAdditionalVisit && (
                  <Button
                    label="Potwierdź"
                    size="medium"
                    onClick={handleSubmit}
                  />
                )}
              </form>
            );
          }}
        </Formik>
        {isAdditionalVisit && (
          <div className="static-alert static-alert--show static-alert--green static-alert--center">
            <div className="static-alert__content">
              Zgłoszono konieczność umówienia montażu uzupełniającego
            </div>
            <div>
              <SVG type={SVG_TYPE.CHECK_GREEN} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FollowUpMeeting;
