import "./order-preview.scss";
import { Modal, OrderStatus, SVG, Button } from "components";
// import { Modal, OrderStatus, SVG, FileItem, Button } from "components";
import { ROUTE_ENUM, SVG_TYPE, USER_ROLE, ORDER_ENUM, FILE_ENUM } from "enums";
import { useNavigate } from "react-router";
import { useGetOrderDetails } from "hooks/orders";
import {
  DeterminedAndArrangedInstallation,
  ArchivalDatesDetails,
  OrderInvoice,
  AdditionalAppointment,
} from "features/orders";
import { OrderListType } from "types/OrderTypes";
import { hasAccess, hasOrderStatus } from "utils";
import { Photos } from "features/Photos";
import { UserType } from "types/UsersTypes";

interface OrderPreviewType {
  isOpen: boolean;
  CalendarEventPreview?: boolean;
  onClose: () => void;
  order: OrderListType;
  page?: number;
  authorizedUser: UserType;
}

const OrderPreview = ({
  isOpen,
  onClose,
  order,
  page,
  authorizedUser,
  CalendarEventPreview = false,
}: OrderPreviewType) => {
  const userRole = authorizedUser.role;
  const navigate = useNavigate();
  const { measurer, fitter, admin, scheduleManager, advisor } = USER_ROLE;
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    VERIFICATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    MEASUREMENTS_DONE,
  } = ORDER_ENUM;
  const { ACTIVE_ORDER, ARRANGING_MEASUREMENT, DASHBOARD_STATISTICS } =
    ROUTE_ENUM;

  const isMeasurer = hasAccess(userRole, [measurer]);
  const isFitter = hasAccess(userRole, [fitter]);
  const isAdmin = hasAccess(userRole, [admin]);
  const isAdvisor = hasAccess(userRole, [advisor]);
  const isScheduleManager = hasAccess(userRole, [scheduleManager]);
  const isDownloadPhotos = hasAccess(userRole, [admin, scheduleManager]);
  const { data: orderDetails, isLoading } = useGetOrderDetails(
    order.uuid,
    isOpen,
    isDownloadPhotos
  );
  const isMeasurementsButtonActive =
    (isFitter || isMeasurer) &&
    orderDetails &&
    hasOrderStatus(orderDetails.status, [FIXED_MEASUREMENTS]);

  const orderStatus = orderDetails?.status;
  const installations =
    orderDetails?.measurementsArrangedInstallations &&
    orderDetails?.measurementsArrangedInstallations?.length > 0;

  const isTimeReportAvailable = hasAccess(
    userRole,
    [admin],
    orderDetails?.isTimeReportAvailable
  );
  const handleClickAppointment = (edit = "", groupUuid?: null) => {
    navigate(
      `${ARRANGING_MEASUREMENT}?uuid=${order.uuid}&tab=${
        installations ? "fixedOrders" : orderStatus
      }${groupUuid ? `&groupUuid=${groupUuid}` : ""}${edit ? `&edit=true` : ""}`
    );
  };
  const handleClickMeasurement = () => {
    navigate(
      `${ACTIVE_ORDER}?page=${page}&orderUuid=${order.uuid}&tab=${orderStatus}${
        order.groupUuid ? `&groupUuid=${order.groupUuid}` : ""
      }`
    );
  };

  const isInstallationToBeReleased =
    orderStatus === INSTALLATION_TO_BE_RELEASED;
  const isStatusFixedMeasurement = orderStatus === FIXED_MEASUREMENTS;
  const isStatusFixedVerification = orderStatus === FIXED_VERIFICATION;
  const isStatusFixedInstallation = orderStatus === FIXED_INSTALLATION;
  const isStatusMeasurementsDone = orderStatus === MEASUREMENTS_DONE;

  const isShowAssignedEmployee =
    !isMeasurer &&
    !isFitter &&
    !isInstallationToBeReleased &&
    !isStatusMeasurementsDone &&
    !isStatusFixedInstallation;

  const isActiveEditButton = hasAccess(
    userRole,
    [scheduleManager],
    isStatusFixedMeasurement ||
      isStatusFixedVerification ||
      isStatusFixedInstallation
  );

  const isActiveMakeAppointment = hasAccess(
    userRole,
    [scheduleManager],
    hasOrderStatus(orderStatus, [
      MEASUREMENTS_TO_BE_RELEASED,
      VERIFICATION_TO_BE_RELEASED,
    ])
  );

  const handleShowTimeReport = (orderDetails: OrderListType) => {
    const { id, uuid } = orderDetails;
    navigate(
      `${DASHBOARD_STATISTICS}?tab=orders&order=${id}${
        CalendarEventPreview
          ? `&calendar=true${uuid ? `&uuid=${uuid}` : ""}`
          : "&orderList=true"
      }`
    );
  };

  return (
    <>
      {!isLoading && (
        <Modal
          side="right"
          className="order-preview"
          isOpened={isOpen}
          onClose={() => onClose()}
        >
          <>
            {orderDetails && (
              <div className="order-preview">
                <div className="modal-heading__with-close">
                  <div className="modal-heading">Podgląd zlecenia</div>
                  <OrderStatus
                    className="order-preview__status-desktop"
                    status={orderDetails.status}
                  />
                  <div
                    className="modal-heading__close order-preview__heading"
                    onClick={() => onClose()}
                  >
                    <span>Zamknij</span>
                    <SVG type={SVG_TYPE.CLOSE} />
                  </div>
                </div>
                <div className="order-preview__status-mobile">
                  <OrderStatus status={orderDetails.status} />
                </div>
                <div className="order-preview__label">{orderDetails.name}</div>
                <div className="modal__separator" />
                <AdditionalAppointment
                  orderDetails={orderDetails}
                  authorizedUser={authorizedUser}
                />
                <div className="modal__informations">
                  <div className="modal__information-header">
                    Imię i nazwisko klienta
                  </div>
                  <div className="modal__information-descript">
                    {orderDetails.clientName}
                  </div>
                  <div className="modal__information-header">Telefon</div>
                  <div className="modal__information-descript">
                    {orderDetails.phoneNumber}
                  </div>
                  <div className="modal__information-header">Adres email</div>
                  <div className="modal__information-descript">
                    {orderDetails.email}
                  </div>
                  <div className="modal__information-header">Adres klienta</div>
                  <div className="modal__information-descript">
                    {orderDetails.address}
                  </div>

                  <ArchivalDatesDetails
                    userRole={userRole}
                    orderDetails={orderDetails}
                  />
                  {isShowAssignedEmployee && (
                    <>
                      <div className="modal__information-header">
                        Przypisany pomiarowiec
                        {isActiveMakeAppointment && (
                          <Button
                            size="small"
                            label="Umów"
                            onClick={() => handleClickAppointment()}
                          />
                        )}
                        {isActiveEditButton && (
                          <Button
                            size="small"
                            label="Edycja"
                            onClick={() => handleClickAppointment()}
                          />
                        )}
                      </div>
                      <div className="modal__information-descript">
                        {(orderDetails.employee && isStatusFixedMeasurement) ||
                        (orderDetails.employee && isStatusFixedVerification) ? (
                          <>
                            {orderDetails.employee?.firstName}{" "}
                            {orderDetails.employee?.lastName}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </>
                  )}
                  {isStatusFixedMeasurement && (
                    <>
                      <div className="modal__information-header">
                        {isFitter || isMeasurer || isAdmin ? (
                          "Uwagi od grafikowca"
                        ) : (
                          <>
                            {isInstallationToBeReleased
                              ? "Uwagi dla montera"
                              : "Uwagi dla pomiarowca"}
                          </>
                        )}
                      </div>
                      <div className="modal__information-descript">
                        {orderDetails?.comments || "-"}
                      </div>
                    </>
                  )}

                  <OrderInvoice
                    userRole={userRole}
                    orderDetails={orderDetails}
                  />
                  <DeterminedAndArrangedInstallation
                    order={order}
                    userRole={userRole}
                    orderDetails={orderDetails}
                    handleClickAppointment={handleClickAppointment}
                  />
                  {/* <div className="modal__information-header">Oferta</div>
                      <div className="order-preview__information-file">
                        {orderDetails?.files.length > 0 &&
                          orderDetails?.files.map((item) => {
                            return (
                              <>
                                <FileItem
                                  name={item.name as MEASUREMENTS_TYPE}
                                />
                              </>
                            );
                          })}
                      </div> */}
                  {isMeasurementsButtonActive && (
                    <Button
                      className="order-preview__measurer-button"
                      label="Wykonaj"
                      onClick={() => handleClickMeasurement()}
                    />
                  )}
                  {(isAdmin || isScheduleManager || isAdvisor) && (
                    <>
                      <Photos
                        userRole={userRole}
                        type={FILE_ENUM.MEASUREMENTS_PHOTO}
                        orderDetails={orderDetails}
                        label="Zdjęcia z pomiaru"
                      />
                      <Photos
                        userRole={userRole}
                        type={FILE_ENUM.INSTALLATION_PHOTO}
                        orderDetails={orderDetails}
                        label="Zdjęcia z montażu"
                      />
                      <Photos
                        userRole={userRole}
                        type={FILE_ENUM.ADDITIONAL_VISIT_INSTALLATION_PHOTO}
                        orderDetails={orderDetails}
                        label="Zdjęcia z montażu uzupełniającego"
                      />
                    </>
                  )}
                  {isTimeReportAvailable && (
                    <Button
                      className="order-preview__measurer-button"
                      label="Zobacz raport czasowy"
                      size="small"
                      stroke
                      onClick={() => handleShowTimeReport(orderDetails)}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default OrderPreview;
