import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SingleSectionElement,
  TypeOfRadiobox,
  WindowSillsWithDrawing,
} from "features/addingMeasurement";
import { Button, Input, Select, Textarea } from "components";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import { usePutWindowSillsInfo, useStartMeasurement } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";
import { useNavigate } from "react-router";
import { useUploadFile } from "hooks/utils";

interface WindowSillsMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const WindowSillsMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: WindowSillsMeasurementValuesType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAddNew, setIsAddNew] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const navigate = useNavigate();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const initialMeasurementInstallationInfo = measurementInfo?.measurementInfo;
  // const windowSillsFile = initialMeasurementInstallationInfo?.windowSills.map(
  //   (sill: any) => sill.file || null
  // );
  const windowSillsFile = initialMeasurementInstallationInfo?.windowSills
    .map((sill: any) => sill.file)
    .filter((file: any) => file !== null && file !== undefined);

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementUuid", data.measurement.uuid);
      newSearchParams.set(
        "measurementPage",
        data.measurement.lastFinishedPage + 1 || "2"
      );
      setSearchParams(newSearchParams, { replace: true });
      setStep(data.measurement.lastFinishedPage + 1 || 2);
    },
  });

  const uploadFile = useUploadFile();

  const { mutate: editInfo } = usePutWindowSillsInfo({
    onSuccess: async ({ measurement }) => {
      const windowSill = measurement.measurementInfo.windowSills;
      await windowSill.forEach(async (windowSill: any, index: number) => {
        const currentFile = drawings[index] as any;
        if (currentFile instanceof File) {
          await uploadFile.mutate({
            parentUuid: windowSill.uuid,
            type: "roomDrawingWindowSills",
            files: [currentFile],
          });
        }
      });
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "4");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
      if (isAddNew) {
        setIsAddNew(false);
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
      } else {
        goToList();
      }
    },
  });

  const validationSchema = Yup.object().shape({
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    windowSill: Yup.array()
      .of(
        Yup.object().shape({
          length: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
          width: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
          amount: Yup.number()
            .required(VALIDATION_MESSAGES.REQUIRED)
            .min(1, VALIDATION_MESSAGES.REQUIRED),
          connectingWithAnother: Yup.boolean().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          comments: Yup.string()
            .nullable()
            .test(
              "comments-required",
              VALIDATION_MESSAGES.REQUIRED,
              function (value) {
                const { connectingWithAnother } = this.parent;
                if (connectingWithAnother) {
                  return typeof value === "string" ? !!value : false;
                }
                return true;
              }
            ),
          drawing: Yup.string()
            .nullable()
            .test(
              "drawing-required",
              VALIDATION_MESSAGES.REQUIRED,
              function (value) {
                const { connectingWithAnother } = this.parent;
                if (connectingWithAnother) {
                  return value ? true : false;
                }
                return true;
              }
            ),
        })
      )
      .min(1, VALIDATION_MESSAGES.REQUIRED)
      .required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = (values: any) => {
    setDrawings(values.drawing);
    delete values.drawing;
    delete values.file;
    editInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          roomName: initialMeasurementInstallationInfo?.roomName || "",
          material: initialMeasurementInstallationInfo?.material || "",
          thickness: initialMeasurementInstallationInfo?.thickness || "",
          color: initialMeasurementInstallationInfo?.color || "",
          corners: initialMeasurementInstallationInfo?.corners || "",
          numberOfAssemblyTeams:
            initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
          plannedAssemblyTime:
            initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
          comments: initialMeasurementInstallationInfo?.comments || "",
          windowSill: initialMeasurementInstallationInfo?.windowSills || [],
          file: windowSillsFile || [],
          drawing: windowSillsFile || [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          submitCount,
          touched,
          validateField,
          values,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                name="material"
                label="Materiał"
                optional
                options={[
                  {
                    label: "PCV",
                    value: "PCV",
                  },
                  {
                    label: "MDF",
                    value: "MDF",
                  },
                  {
                    label: "Konglomerat",
                    value: "conglomerate",
                  },
                  {
                    label: "Granit",
                    value: "granite",
                  },
                  {
                    label: "Nakładka",
                    value: "overlay",
                  },
                  {
                    label: "Stalowe",
                    value: "steel",
                  },
                  {
                    label: "Aluminiowe",
                    value: "aluminum",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <SingleSectionElement
                touched={touched}
                name="thickness"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Grubość"
                  rightPlaceholder="mm"
                  type="number"
                  maxLength={4}
                  name="thickness"
                  component={Input}
                  optional
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="asdas"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Rogi"
                  name="corners"
                  optional
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "rounded", label: "Zaokrąglone" },
                    { value: "straightAngle", label: "Kąt prosty" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="color"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Kolor"
                  maxLength={30}
                  optional
                  name="color"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                name="windowSill"
                validateField={validateField}
                component={WindowSillsWithDrawing}
              />
              <SingleSectionElement
                touched={touched}
                name="comments"
                errors={errors}
              >
                <Field
                  className="am-margin-top"
                  label="Dodatkowe uwagi"
                  optional
                  name="comments"
                  component={Textarea}
                  maxLength={250}
                />
              </SingleSectionElement>
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WindowSillsMeasurementValues;
