import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { SkirtingBoards } from "features/addingMeasurement";
import { Button, Textarea } from "components";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import {
  usePutSkirtingBoardInfo,
  useStartMeasurement,
} from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";
import { useNavigate } from "react-router";

interface SkirtingBoardsMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const SkirtingBoardsMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: SkirtingBoardsMeasurementValuesType) => {
  const navigate = useNavigate();
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const [isAddNew, setIsAddNew] = useState(false);

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementUuid", data.measurement.uuid);
      newSearchParams.set(
        "measurementPage",
        data.measurement.lastFinishedPage + 1 || "2"
      );
      setSearchParams(newSearchParams, { replace: true });
      setStep(data.measurement.lastFinishedPage + 1 || 2);
    },
  });

  const { mutate: editSkirtingBoard } = usePutSkirtingBoardInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "4");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
      if (isAddNew) {
        setIsAddNew(false);
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
      } else {
        goToList();
      }
    },
  });

  const validationSchema = Yup.object().shape({
    skirtingBoards: Yup.array()
      .of(
        Yup.object().shape({
          length: Yup.string()
            .required(VALIDATION_MESSAGES.REQUIRED)
            .matches(/^\d+$/, VALIDATION_MESSAGES.REQUIRED)
            .test(
              "is-greater-than-zero",
              VALIDATION_MESSAGES.REQUIRED,
              (value) => Number(value) > 0
            ),
          amount: Yup.string()
            .required(VALIDATION_MESSAGES.REQUIRED)
            .matches(/^\d+$/, VALIDATION_MESSAGES.REQUIRED)
            .test(
              "is-greater-than-zero",
              VALIDATION_MESSAGES.REQUIRED,
              (value) => Number(value) > 0
            ),
        })
      )
      .test(
        "skirtingBoards-not-empty",
        VALIDATION_MESSAGES.REQUIRED,
        function (skirtingBoards) {
          if (!skirtingBoards || skirtingBoards.length === 0) return false;
          return skirtingBoards.every((board) => board.length && board.amount);
        }
      ),
  });

  const handleOnSubmit = (values: any) => {
    editSkirtingBoard(values);
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          skirtingBoards: initialMeasurementInfo?.skirtingBoards || [],
          comments: initialMeasurementInfo?.comments || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, submitCount, values }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field errors={errors} component={SkirtingBoards} />
              {values.skirtingBoards.length > 0 && (
                <Field
                  className="am-margin-top"
                  errors={errors}
                  label="Dodatkowe uwagi"
                  name="comments"
                  optional
                  component={Textarea}
                  maxLength={250}
                />
              )}
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SkirtingBoardsMeasurementValues;
